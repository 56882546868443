export default {
    init(keyfact: HTMLElement) {
        const text = keyfact.innerHTML;

        if (!text) return;

        if (text.includes(' - ')) {
            const replace = text.replaceAll(
                ' - ',
                '<span class="separate-icon"></span>'
            );

            keyfact.innerHTML = replace;
        }
    }
};
