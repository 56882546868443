import utils from './utils';

export default {
    init(section: HTMLElement) {
        const accordions = section.querySelectorAll('.mobile-accordion');
        const md = utils.getMQValue('md');

        if (!accordions) return;

        const accordionHeads = section.querySelectorAll(
            '.accordion-head'
        ) as NodeListOf<HTMLElement>;

        const accordionBodies = section.querySelectorAll(
            '.accordion-body'
        ) as NodeListOf<HTMLElement>;

        if (window.matchMedia('screen and (max-width: ' + md + 'px)').matches) {
            if (accordionHeads.length) {
                accordionHeads.forEach((accordionHead) => {
                    accordionHead.addEventListener(
                        'click',
                        function (event: Event) {
                            const accordion = accordionHead.parentElement;

                            if (!accordion) return;

                            const activeAccordionHead = accordion.querySelector(
                                '.accordion-head.active'
                            );
                            event.stopPropagation();

                            if (accordionBodies.length) {
                                accordionBodies.forEach((acBody) => {
                                    if (
                                        !acBody.classList.contains(
                                            'animate-body'
                                        )
                                    ) {
                                        acBody.classList.add('animate-body');
                                    }
                                });
                            }

                            if (
                                activeAccordionHead &&
                                activeAccordionHead != accordionHead
                            ) {
                                activeAccordionHead.classList.remove('active');
                                const activeAccordionBody =
                                    accordion.querySelector(
                                        '.accordion-body.active'
                                    );

                                if (activeAccordionBody) {
                                    activeAccordionBody.setAttribute(
                                        'style',
                                        'maxHeight:0;'
                                    );
                                }
                            }

                            const accordionBody = accordion.querySelector(
                                '.accordion-body'
                            ) as HTMLElement;

                            // close current accordion if its active
                            if (accordionHead.classList.contains('active')) {
                                accordionHead.classList.remove('active');
                                if (accordionBody) {
                                    accordionBody.classList.remove('active');
                                    accordionBody.setAttribute(
                                        'style',
                                        'maxHeight:0;'
                                    );
                                }
                            } else {
                                // close all accordions
                                if (accordionBodies.length) {
                                    accordionBodies.forEach(
                                        (acBody: HTMLElement) => {
                                            acBody.classList.remove('active');
                                            acBody.setAttribute(
                                                'style',
                                                'maxHeight:0;'
                                            );
                                        }
                                    );
                                }

                                if (accordionHeads.length) {
                                    accordionHeads.forEach(
                                        (acHead: HTMLElement) => {
                                            acHead.classList.remove('active');
                                        }
                                    );
                                }

                                // set clicked accordion active
                                accordionHead.classList.add('active');
                                if (accordionBody) {
                                    accordionBody.classList.add('active');
                                    accordionBody.style.maxHeight =
                                        accordionBody.scrollHeight + 'px';
                                }
                            }
                        }
                    );
                });

                // Open first accordion
                const firstActiveAccordionHead = section.querySelector(
                    '.accordion-head.active'
                ) as HTMLElement;
                const firstActiveAccordionBody = section.querySelector(
                    '.accordion-body.active'
                ) as HTMLElement;

                if (firstActiveAccordionHead) {
                    firstActiveAccordionBody.style.maxHeight =
                        firstActiveAccordionBody.scrollHeight + 20 + 'px';
                }
            }
        }
    }
};
