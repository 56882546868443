// import htmx from './htmx';

export default {
    init() {
        const initQuestions = () => {
            const questions = document.querySelectorAll(
                '.js-faq-wrapper'
            ) as NodeListOf<HTMLElement>;

            if (questions.length <= 0) {
                return;
            }

            questions.forEach((question) => {
                const questionButton = question.querySelector('button');

                if (!questionButton) {
                    return;
                }

                questionButton.addEventListener('click', () => {
                    question.classList.toggle('faq-active');
                });
            });
        };

        initQuestions();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).htmx.on('htmx:afterSwap', () => {
            initQuestions();
        });
    }
};
