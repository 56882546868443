export default {
    init() {
        const forms = document.querySelectorAll('form');

        if (!forms) return;

        forms.forEach((form) => {
            const textarea = form.querySelectorAll('textarea');
            textarea.forEach((element) => {
                const parentElement = element.parentElement;

                if (!parentElement) return;

                const inputLabel = parentElement.previousElementSibling;

                if (!inputLabel) return;
                element.addEventListener('focus', () => {
                    inputLabel.classList.add('float');
                    inputLabel.classList.add('selected');
                    element.classList.add('selected');
                });

                element.addEventListener('blur', () => {
                    if (element.value.length == 0) {
                        inputLabel.classList.remove('float');
                    }
                    inputLabel.classList.remove('selected');
                    element.classList.remove('selected');
                });
            });

            //Inputs
            const inputs = form.querySelectorAll('input');
            inputs.forEach((input) => {
                const parentElement = input.parentElement;

                if (!parentElement) return;

                const inputLabel = parentElement.previousElementSibling;

                if (!inputLabel) return;
                input.addEventListener('focus', () => {
                    inputLabel.classList.add('float');
                    inputLabel.classList.add('selected');
                    input.classList.add('selected');
                });

                input.addEventListener('blur', () => {
                    if (input.value.length == 0) {
                        inputLabel.classList.remove('float');
                    }

                    inputLabel.classList.remove('selected');
                    input.classList.remove('selected');
                });
            });

            const formId = form.id;

            if (!formId) return;

            const currentForm = document.querySelector('#' + formId);

            if (!currentForm) return;

            const formHandle = currentForm.querySelector(
                'input[name="handle"]'
            ) as HTMLInputElement;
            //Find the CSRF token hidden input, so we can replace it
            const csrfInput = currentForm.querySelector(
                'input[name="CRAFT_CSRF_TOKEN"]'
            );

            if (!formHandle || !csrfInput) return;

            // Fetch the new token for the form and replace the CSRF input with our new one
            fetch(
                '/actions/formie/forms/refresh-tokens?form=' + formHandle.value
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    csrfInput.outerHTML = result.csrf.input;
                });
        });
    }
};
