import './app.scss';
import 'lazysizes';
import swiper from './ts/swiper';
import 'intersection-observer';
import faq from './ts/faq';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'htmx.org';
import navigation from './ts/navigation';
import contactForm from './ts/contact-form';
import cookieBanner from './ts/cookie-banner';
import youtubeVideo from './ts/youtube-video';
import apartmentDescriptionAccordion from './ts/apartment-description-accordion';
import animatedBanner from './ts/animated-banner';
import logoAnimation from './ts/logo-animation';
import 'fslightbox';
import separateIcon from './ts/separate-icon';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    faq.init();

    navigation.init();

    AOS.init();

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(
                swiperElement,
                JSON.parse(swiperElement.dataset.swiperOptions)
            );
        });
    }

    contactForm.init();

    // remove comment if you want to add a cookie banner
    cookieBanner.init();

    const youtubeVideoContainers = document.querySelectorAll(
        '.youtube-video-container'
    ) as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        youtubeVideoContainers.forEach((container) => {
            youtubeVideo.init(container);
        });
    }

    const apartmentDescriptionSections = document.querySelectorAll(
        '.apartment-description'
    ) as NodeListOf<HTMLElement>;
    if (apartmentDescriptionSections.length) {
        apartmentDescriptionSections.forEach((section) => {
            apartmentDescriptionAccordion.init(section);
        });
    }

    animatedBanner.init();

    const logoAnimationContainers = document.querySelectorAll(
        '.logo-animation'
    ) as NodeListOf<HTMLElement>;
    if (logoAnimationContainers.length) {
        logoAnimationContainers.forEach((container) => {
            logoAnimation.init(container);
        });
    }

    const keyfacts = document.querySelectorAll(
        '.keyfact'
    ) as NodeListOf<HTMLElement>;
    if (keyfacts.length) {
        keyfacts.forEach((keyfact) => {
            separateIcon.init(keyfact);
        });
    }
})();
