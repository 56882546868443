let storedMQ = 0;
const mediaQueries = {
    xs: 375,
    sm: 640,
    md: 768,
    lg: 1074,
    xl: 1330,
    twoxl: 1536
};

export default {
    /**
     * Get value of tailwind media queries by passing breakpoint term
     *
     * @param {string} mq
     * @returns
     */
    getMQValue(mq: string) {
        if (mq == 'xs') {
            return mediaQueries.xs;
        }
        if (mq == 'sm') {
            return mediaQueries.sm;
        }
        if (mq == 'md') {
            return mediaQueries.md;
        }
        if (mq == 'lg') {
            return mediaQueries.lg;
        }
        if (mq == 'xl') {
            return mediaQueries.xl;
        }
        if (mq == 'twoxl') {
            return mediaQueries.twoxl;
        }
    },

    /**
     * Check if tailwind media query changed
     *
     * @returns boolean
     */
    checkIfMediaQueryChanged() {
        const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        let currentMQ = 0;

        if (storedMQ == 0) {
            storedMQ = width;
        }

        if (width <= mediaQueries.xs) {
            currentMQ = mediaQueries.xs;
        } else if (width >= mediaQueries.xs && width <= mediaQueries.sm) {
            currentMQ = mediaQueries.sm;
        } else if (width >= mediaQueries.sm && width <= mediaQueries.md) {
            currentMQ = mediaQueries.md;
        } else if (width >= mediaQueries.md && width <= mediaQueries.lg) {
            currentMQ = mediaQueries.lg;
        } else if (width >= mediaQueries.lg && width <= mediaQueries.xl) {
            currentMQ = mediaQueries.xl;
        } else {
            currentMQ = mediaQueries.twoxl;
        }

        if (currentMQ != storedMQ) {
            storedMQ = currentMQ;
            return true;
        }
        return false;
    }
};
