import Swiper, {
    Pagination,
    Navigation,
    SwiperOptions,
    EffectFade,
    Autoplay
} from 'swiper';
import 'swiper/css';

const inizializedSwiper: Swiper[] = [];

export default {
    init(swiperElement: HTMLElement, swiperOptions: SwiperOptions) {
        Swiper.use([Pagination, Navigation, EffectFade, Autoplay]);
        inizializedSwiper.push(new Swiper(swiperElement, swiperOptions));
    },

    disable() {
        inizializedSwiper.forEach(function (element) {
            element.destroy();
        });
    }
};
