import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';

export default {
    init() {
        // the goal is to create a seamless text slider with an automatic endless loop
        // keen slider only supports slides which are 100% of its parent
        // if keen slide content exceeds 100% of width it will cut the content
        // the trick is to set the width of the container to the actual width of the slide content, so 100% slide width can be bigger than 100% of window width
        // we measure the slide width before applying keen specific classes

        // animation speed
        const animationSettings = { duration: 50000, easing: (t: number) => t };

        // get all animated text slider container
        const keenContainers = Array.from(
            document.getElementsByClassName(
                'animated-text-banner'
            ) as HTMLCollectionOf<HTMLElement>
        );

        // setup each animated text slider
        keenContainers.forEach((keenContainer) => {
            // get all slides of the current slider
            const keenSlides = Array.from(
                keenContainer.getElementsByClassName(
                    'slide'
                ) as HTMLCollectionOf<HTMLElement>
            );

            // get number of slides, only continue if there are slides
            const numberOfSlides = keenSlides.length;
            if (numberOfSlides > 0) {
                // get the width of the first slide based on its content
                const firstKeenSlide = keenSlides[0];
                const slideWidth = firstKeenSlide.clientWidth;
                // set the container size to the clientWidth of the actual slide
                keenContainer.style.width = String(slideWidth) + 'px';

                // apply keen slider classes. This will set the slide width to 100% of the container which is basically the width of the actual content of the slide. This can be bigger than the actual width of the window
                keenSlides.forEach((slide) => {
                    slide.classList.add('keen-slider__slide');
                });

                // initialize keen slider and automatically move the slides in a loop
                new KeenSlider(keenContainer, {
                    loop: true,
                    renderMode: 'performance',
                    drag: false,
                    created(s) {
                        s.moveToIdx(
                            numberOfSlides - 1,
                            true,
                            animationSettings
                        );
                    },
                    updated(s) {
                        s.moveToIdx(
                            s.track.details.abs + numberOfSlides - 1,
                            true,
                            animationSettings
                        );
                    },
                    animationEnded(s) {
                        s.moveToIdx(
                            s.track.details.abs + numberOfSlides - 1,
                            true,
                            animationSettings
                        );
                    }
                });
            }
        });
    }
};
