import { lock, clearBodyLocks } from 'tua-body-scroll-lock';

export default {
    init() {
        //////////// Navigation
        const menu = document.querySelector('.main-menu') as HTMLElement;
        if (!menu) {
            return;
        }
        const body = document.querySelector('body') as HTMLElement;
        const burgerBtn = menu.querySelector('.menu-burger') as HTMLElement;

        if (!menu || !burgerBtn || !body) {
            return;
        }

        // Remove class that blocks CSS transitions in header. The class is needed because css animations trigger on load and we don't want it :)
        document.addEventListener('DOMContentLoaded', () => {
            menu.classList.remove('no-animations');
        });

        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        };

        // Adapt menu height if window size changes
        window.addEventListener('resize', () => {
            appHeight();
        });

        burgerBtn.addEventListener('click', () => {
            appHeight();
            menu.classList.toggle('main-menu-active');

            if (menu.classList.contains('main-menu-active')) {
                //open
                lock(menu);
            } else {
                //close
                clearBodyLocks();
            }
        });
    }
};
