import * as Klaro from 'klaro';

export default {
    init(container: HTMLElement) {
        if (!container) return;

        const videoSections = document.querySelectorAll(
            '.youtube-video-section'
        );

        const cookieHint = container.querySelector('.cookie-hint');
        const iframe = container.querySelector('iframe');
        const youtubeVideo = container.querySelector(
            '.youtube-video'
        ) as HTMLElement;

        if (!cookieHint || !iframe || !youtubeVideo) return;

        iframe.remove();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w = window as any;
        w.klaro = {};
        w.klaro = Klaro;

        const observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    const klaroConsents = w.klaro.getManager().loadConsents();

                    if (
                        !cookieHint.classList.contains('hidden') &&
                        klaroConsents &&
                        klaroConsents.youtube
                    ) {
                        youtubeVideo.appendChild(iframe);
                        cookieHint.classList.add('hidden');
                    } else {
                        cookieHint.classList.remove('hidden');
                    }

                    w.dataLayer = w.dataLayer || [];

                    w.dataLayer.push({
                        event: 'video'
                    });
                }
            }, {});
        });

        videoSections.forEach(function (section) {
            observer.observe(section);
        });

        cookieHint.addEventListener('click', () => {
            w.klaro.getManager().updateConsent('youtube', true);
            w.klaro.getManager().saveAndApplyConsents();
            const klaroConsents = w.klaro.getManager().loadConsents();

            if (
                !cookieHint.classList.contains('hidden') &&
                klaroConsents &&
                klaroConsents.youtube
            ) {
                youtubeVideo.appendChild(iframe);
                cookieHint.classList.add('hidden');
            } else {
                cookieHint.classList.remove('hidden');
            }
        });
    }
};
