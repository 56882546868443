import lottie from 'lottie-web';
import anime from 'animejs';

export default {
    init(container: HTMLElement) {
        const animationContainer = container.querySelector(
            '.lottie-player'
        ) as HTMLElement;
        const home = document.querySelector('.home-page') as HTMLElement;
        const timeline = anime.timeline();
        const timelinePage = anime.timeline();

        if (!animationContainer || !home) return;

        home.classList.add('opacity-0');

        const animationUrl = animationContainer.dataset.animationUrl;

        if (!animationUrl) return;

        if (document.cookie.indexOf('animation_played=1') == -1) {
            const lottieAnimation = lottie.loadAnimation({
                container: animationContainer,
                autoplay: true,
                path: animationUrl,
                loop: false
            });

            if (!lottieAnimation) return;

            lottieAnimation.addEventListener('complete', () => {
                timeline.add({
                    targets: container,
                    opacity: [1, 0],
                    duration: 500,
                    easing: 'easeInQuint',
                    complete: () => {
                        container.classList.add('pointer-events-none');
                    }
                });

                timelinePage.add({
                    targets: home,
                    opacity: [0, 1],
                    duration: 500,
                    easing: 'easeInQuint',
                    complete: () => {
                        document.cookie = 'animation_played=1';
                    }
                });
            });
        } else {
            container.classList.add('hidden');
            home.classList.remove('opacity-0');
        }
    }
};
